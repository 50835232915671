<style type="text/css">
  @import "~@/assets/css/common.css";

  .form-control {
    padding: unset;
    border-radius: unset;
  }

  .form-control input {
    border-radius: unset;
    border: unset;
  }
</style>
<style type="text/css" scoped>
  /deep/.el-input__inner {
    height: 100%;
  }

  .tips {
    font-size: 12px;
    margin-bottom: 10px;
    color: rgb(166, 3, 3);
  }
</style>
<script>
  // import $ from "jquery";
  import LoginLeft from "@/views/pages/account/login-left";
  import LoginTop from "@/views/pages/account/login-top";
  import {
    updateStrongPassword,
    loginJump
  } from "@/api/index.js";
  export default {
    components: {
      LoginLeft,
      LoginTop,
    },
    data() {
      const checkPassword = (rule, value, cb) => {
        const Password =
          /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
        if (Password.test(value)) {
          return cb();
        }
        cb(new Error("密码格式不正确(不少于6位数字字母组合)"));
      };
      const checkPassword_again = (rule, value, cb) => {
        const regPassword = this.weekPassword.mm;
        if (regPassword == value) {
          return cb();
        }
        cb(new Error("两次密码不相同"));
      };
      return {
        baseConfig: {},
        weekPassword: {
          xm: "",
          mm: "",
          mm_again: "",
        },
        rules: {
          xm: [{
            required: true,
            message: "请输入您的姓名",
            trigger: "change",
          }, ],

          mm: [{
              required: true,
              message: "请输入您的密码",
              trigger: "change",
            },
            {
              validator: checkPassword,
              trigger: "change",
            },
          ],
          mm_again: [{
              required: true,
              message: "请再次输入您的密码",
              trigger: "change",
            },
            {
              validator: checkPassword_again,
              trigger: "change",
            },
          ],
        },
      };
    },
    methods: {
      checkForm: function() {
        this.$refs["weekPassword"].validate((valid) => {
          if (valid) {
            var formData = {
              xm: this.weekPassword.xm,
              xmm: this.weekPassword.mm,
            };
            updateStrongPassword(formData).then((res) => {
              if (res.status) {
                this.$message({
                  message: "修改密码成功",
                  type: "success",
                });
                this.$router.push("/login");
                return false;
              }
            });
          }
        });
      },
      login() {
        var userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        if (userInfo) {
          loginJump(this, userInfo)
        }
      }
    },
    created() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.weekPassword.xm = userInfo.xm;
      let conf=JSON.parse(window.localStorage.getItem('BaseConfig'))
      this.baseConfig=conf?conf:{}

    },
  };
</script>

<template>
  <div>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex">
            <LoginLeft />

            <div class="log-r">
              <p class="log-tips">弱密码修改</p>
              <div class="mt-4 login-main">
                <div class="tips flexList">
                  <i class="iconfont icon-tips-one mr-2"></i>您的密码不符合安全要求，必须修改后方可登录。
                </div>
                <el-form :model="weekPassword" :rules="rules" ref="weekPassword">
                  <el-form-item prop="xm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/user.png" alt="" /></span>
                      </div>
                      <el-input readonly type="text" placeholder="姓名" class="form-control h-100"
                        v-model="weekPassword.xm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/lock.png" alt="" /></span>
                      </div>
                      <el-input id="password" type="password" placeholder="请设置新的密码" class="form-control h-100"
                        auto-complete="new-password" v-model="weekPassword.mm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm_again">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/completed.png" alt="" /></span>
                      </div>
                      <el-input type="password" placeholder="请重新输入一次新密码" class="form-control h-100"
                        v-model="weekPassword.mm_again" />
                    </div>
                    <div class="text-dark font-size-12">
                      密码应不少于6位，由大小写字母、数字、特殊符号2种以上组成。
                    </div>
                  </el-form-item>
                  <div class="login-btn">
                    <button type="button" class="btn btn-info w-100" @click="checkForm">
                      确认设置密码
                    </button>
                  </div>
                  <div class="text-center mt-3">

                    <p class="text-dark" @click="login" style="cursor: pointer;">
                      放弃更改，进入主页
                    </p>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="login-footer text-center mt-4">
            版权所有：{{baseConfig.WebsiteName}}
<!--            © {{baseConfig.SystemYear}} Rights Reserved-->
            <!-- 版权所有：四川省人事人才考试测评基地 © 2021 Rights Reserved -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
